import { create } from "zustand";

import { SalesPerson } from "@/salesTypes/salesPersons";

type UserDataStore = {
	salesPersons: SalesPerson[];
	activeUser: number | null;
	setSalesPersons: (newSalesPersons: SalesPerson[]) => void;
	updateSalesPersons: (newSalesPersons: SalesPerson[]) => void;
	addToSalesPersons: (newSalesPersons: SalesPerson[]) => void;
	setActiveUser: (id: number) => void;
};

export const useUserDataStore = create<UserDataStore>((set) => ({
	salesPersons: [],
	activeUser: null,
	setSalesPersons: (persons: SalesPerson[]) => set({ salesPersons: persons }),
	updateSalesPersons: (persons: SalesPerson[]) => {
		set((state) => {
			const newPersonIDs = persons.map((sh) => sh.id);
			const existingPersons = state.salesPersons.filter(
				(sh) => !newPersonIDs.includes(sh.id)
			);

			return {
				salesPersons: [...existingPersons, ...persons],
			};
		});
	},
	addToSalesPersons: (persons: SalesPerson[]) => {
		set((state) => {
			const existingPersons = state.salesPersons.map((sh) => sh.id);
			const newPersons = persons.filter(
				(sh) => !existingPersons.includes(sh.id)
			);

			return {
				salesPersons: [...newPersons, ...state.salesPersons],
			};
		});
	},
	setActiveUser: (id: number) => set({ activeUser: id }),
}));
